<template>
  <Loading v-if="isLoading" />
  <main v-else>
    <div>
      <h1 class="text-md mt-2 font-medium">Terminal Pricing</h1>
    </div>
    <div class="big-shadow w-full py-24 px-5 mt-10 rounded-xl">
      <div class="grid grid-cols-3 gap-10">
        <div class="shadow rounded-lg p-4">
          <p class="text-center text-sm to-gray-800 mt-2 font-medium">
            Traction SOLO
          </p>
          <div class="flex mt-10 flex-col px-2">
            <div>
              <img
                class="h-40"
                src="@/assets/images/terminals/TractionMpos.png"
                alt=""
              />
            </div>
            <div class="mt-3">
              <div class="flex items-center">
                <h1 class="text-sm text-primaryGray">Outright purchase:</h1>
                <h2 class="text-sm ml-2">
                  ₦{{ mposPayload.outrightPurchase }}
                </h2>
              </div>
              <div class="flex items-center">
                <h1 class="text-sm text-primaryGray">Lease to own:</h1>
                <h2 class="text-sm ml-2">₦{{ mposPayload.leaseToOwn }}</h2>
              </div>
              <div class="flex items-center">
                <h1 class="text-sm text-primaryGray">Deposit Amount:</h1>
                <h2 class="text-sm ml-2">
                  ₦{{ mposPayload.leaseToOwnDepositAmount }}
                </h2>
              </div>
              <div class="flex items-center">
                <h1 class="text-sm text-primaryGray">Subscription:</h1>
                <h2 class="text-sm ml-2">₦{{ mposPayload.subscription }}</h2>
              </div>
            </div>
            <div>
              <h1 class="text-sm text-primaryGray underline text-gray-700 mt-5">
                Accessories:
              </h1>
              <div
                v-for="(item, index) in mposPayload.accessories"
                :key="index"
              >
                <div class="flex items-center">
                  <h2 class="text-sm">{{ item.accessoriesType }}:</h2>
                  <h2 class="text-sm ml-2">₦{{ item.price }}</h2>
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="!mposTerminal"
            @click="mposTerminal = true"
            class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
          >
            Edit
          </button>
          <div v-else>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Outright Purchase</label>
              <input
                v-model="mposPayload.outrightPurchase"
                placeholder="Outright Purchase"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Lease To Own</label>
              <input
                v-model="mposPayload.leaseToOwn"
                placeholder="Lease To Own"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Deposit amount</label>
              <input
                v-model="mposPayload.leaseToOwnDepositAmount"
                placeholder="Add deposit amount"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>

            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Subscription</label>
              <input
                v-model="mposPayload.subscription"
                placeholder="Lease To Own"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        </div>
        <div class="shadow rounded-lg p-4">
          <p class="text-center text-sm to-gray-800 mt-2 font-medium">
            Traction PRO
          </p>
          <div class="flex items-center mt-10 justify-between px-2">
            <div>
              <img
                src="@/assets/images/terminals/TractionLinux.png"
                class="h-40"
                alt=""
              />
            </div>
            <div>
              <div class="ml-5">
                <div>
                  <h1 class="text-sm text-primaryGray">Outright purchase</h1>
                  <h2 class="text-sm">₦{{ linuxPayload.outrightPurchase }}</h2>
                </div>
                <div class="mt-2">
                  <h1 class="text-sm text-primaryGray">Lease to own</h1>
                  <h2 class="text-sm">
                    ₦{{ linuxPayload.leaseToOwn }}(Per day for full term of
                    lease)
                  </h2>
                </div>
                <div class="mt-2">
                  <h1 class="text-sm text-primaryGray">Deposit Amount</h1>
                  <h2 class="text-sm">
                    ₦{{ linuxPayload.leaseToOwnDepositAmount }}
                  </h2>
                </div>
                <div class="mt-2">
                  <h1 class="text-sm text-primaryGray">Subscription</h1>
                  <h2 class="text-sm">
                    ₦{{ linuxPayload.subscription }}(Per day)
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="!linuxTerminal"
            @click="linuxTerminal = true"
            class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
          >
            Edit
          </button>
          <div v-else>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Outright Purchase</label>
              <input
                v-model="linuxPayload.outrightPurchase"
                placeholder="Outright Purchase"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Lease To Own</label>
              <input
                v-model="linuxPayload.leaseToOwn"
                placeholder="Lease To Own"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Deposit amount</label>
              <input
                v-model="linuxPayload.leaseToOwnDepositAmount"
                placeholder="Add deposit amount"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Subscription</label>
              <input
                v-model="linuxPayload.subscription"
                placeholder="Lease To Own"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex items-center">
              <button
                @click="linuxPrice"
                class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
              >
                Save
              </button>
              <button
                @click="linuxTerminal = false"
                class="block mt-12 ml-3 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="shadow rounded-lg p-4">
          <p class="text-center text-sm to-gray-800 mt-2 font-medium">
            Traction One
          </p>
          <div class="flex items-center mt-10 justify-between px-4">
            <div>
              <img
                src="@/assets/images/terminals/TractionAndroid.png"
                class="h-40"
              />
            </div>
            <div class="ml-5">
              <div>
                <h1 class="text-sm text-primaryGray">Outright purchase</h1>
                <h2 class="text-sm">₦{{ androidPayload.outrightPurchase }}</h2>
              </div>
              <div class="mt-2">
                <h1 class="text-sm text-primaryGray">Lease to own</h1>
                <h2 class="text-sm">
                  ₦{{ androidPayload.leaseToOwn }}(Per day for full term of
                  lease)
                </h2>
              </div>
              <div class="mt-2">
                <h1 class="text-sm text-primaryGray">Deposit Amount</h1>
                <h2 class="text-sm">
                  ₦{{ androidPayload.leaseToOwnDepositAmount }}
                </h2>
              </div>
              <div class="mt-2">
                <h1 class="text-sm text-primaryGray">Subscription</h1>
                <h2 class="text-sm">
                  ₦{{ androidPayload.subscription }}(Per day)
                </h2>
              </div>
            </div>
          </div>

          <button
            v-if="!androidTerminal"
            @click="androidTerminal = true"
            class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
          >
            Edit
          </button>
          <div v-else>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Outright Purchase</label>
              <input
                v-model="androidPayload.outrightPurchase"
                placeholder="Outright Purchase"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Lease To Own</label>
              <input
                v-model="androidPayload.leaseToOwn"
                placeholder="Lease To Own"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Deposit amount</label>
              <input
                v-model="androidPayload.leaseToOwnDepositAmount"
                placeholder="Add deposit amount"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Subscription</label>
              <input
                v-model="androidPayload.subscription"
                placeholder="Lease To Own"
                type="number"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex items-center">
              <button
                @click="androidPrice"
                class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
              >
                Save
              </button>
              <button
                @click="androidTerminal = false"
                class="block mt-12 ml-3 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mposTerminal" class="mt-5">
        <div class="flex items-center">
          <h1 class="text-sm text-primaryGray">Accessories</h1>
          <svg
            @click="
              mposPayload.accessories.push({ accessoriesType: '', price: 0 })
            "
            class="ml-5 w-5 h-5 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.83 0 0 114.83 0 256s114.83 256 256 256 256-114.85 256-256S397.17 0 256 0zm0 472.34C136.72 472.34 39.66 375.3 39.66 256S136.72 39.66 256 39.66 472.34 136.7 472.34 256 375.3 472.34 256 472.34z"
            />
            <path
              d="M355.15 234.39h-79.32v-79.32a19.82 19.82 0 10-39.66 0v79.32h-79.32a19.82 19.82 0 100 39.66h79.32v79.31a19.82 19.82 0 1039.66 0v-79.31h79.32a19.82 19.82 0 100-39.66z"
            />
          </svg>
        </div>
        <div v-for="(accessory, index) in mposPayload.accessories" :key="index">
          <div class="flex mt-5">
            <div class="flex flex-col">
              <label for="email" class="text-xs">Accessory Type</label>
              <input
                v-model="accessory.accessoriesType"
                placeholder="Acessory Type"
                type="text"
                class="shadow appearance-none text-xs border rounded py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div class="flex ml-5 flex-col">
              <label for="email" class="text-xs">Price</label>
              <input
                v-model="accessory.price"
                placeholder="Price"
                type="number"
                class="shadow appearance-none text-xs border pl-2 rounded w-full py-2 leading-5 px-1 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
            <div>
              <svg
                @click="mposPayload.accessories.splice(index, 1)"
                xmlns="http://www.w3.org/2000/svg"
                class="ml-5 w-5 h-5 mt-6 cursor-pointer"
                viewBox="0 0 512 512"
              >
                <path
                  d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"
                />
                <path
                  d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"
                />
                <path
                  d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <button
            @click="mposPrice"
            class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
          >
            Save
          </button>

          <button
            @click="mposTerminal = false"
            class="block mt-12 ml-3 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-8 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "./general-components/Loading.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      mposTerminal: false,
      linuxTerminal: false,
      androidTerminal: false,
      mposPayload: {
        accessories: [],
        outrightPurchase: 0,
        leaseToOwn: 0,
        subscription: 0,
      },
      linuxPayload: {
        outrightPurchase: 0,
        leaseToOwn: 0,
        subscription: 0,
      },
      androidPayload: {
        outrightPurchase: 0,
        leaseToOwn: 0,
        subscription: 0,
      },
      terminalsPrice: [],
    };
  },
  methods: {
    ...mapActions[("FETCH_ALL_TERMINAL_PRICE", "CREATE_TERMINAL_PRICING")],
    async makeRequest() {
      this.isLoading = true;
      let res = await this.$store.dispatch("FETCH_ALL_TERMINAL_PRICE");
      let items = res.data.items || [];
      if (items.length) {
        this.isLoading = false;
        items.forEach((x) => {
          if (x.terminalType === "MPOS") {
            this.mposPayload.leaseToOwn = x.leaseToOwn;
            this.mposPayload.outrightPurchase = x.outrightPurchase;
            this.mposPayload.accessories = x.accessories;
            this.mposPayload.subscription = x.subscription;
            this.mposPayload.leaseToOwnDepositAmount =
              x.leaseToOwnDepositAmount;
          }
          if (x.terminalType === "LINUX") {
            this.linuxPayload.leaseToOwn = x.leaseToOwn;
            this.linuxPayload.outrightPurchase = x.outrightPurchase;
            this.linuxPayload.subscription = x.subscription;
            this.linuxPayload.leaseToOwnDepositAmount =
              x.leaseToOwnDepositAmount;
          }
          if (x.terminalType === "ANDROID") {
            this.androidPayload.leaseToOwn = x.leaseToOwn;
            this.androidPayload.outrightPurchase = x.outrightPurchase;
            this.androidPayload.subscription = x.subscription;
            this.androidPayload.leaseToOwnDepositAmount =
              x.leaseToOwnDepositAmount;
          }
        });
      }
    },
    async mposPrice() {
      this.isLoading = true;
      let payload = {
        TerminalType: "Mpos",
        OutrightPurchase: this.mposPayload.outrightPurchase,
        LeaseToOwn: this.mposPayload.leaseToOwn,
        Accessories: this.mposPayload.accessories,
        Subscription: "0",
        leaseToOwnDepositAmount: this.mposPayload.leaseToOwnDepositAmount,
      };
      let res = await this.$store.dispatch("CREATE_TERMINAL_PRICING", payload);
      if (res.error === "MPOS terminal already exist") {
        await this.$store.dispatch("UPDATE_TERMINAL_PRICING", payload);
        this.mposTerminal = false;
        alert("Terminal Pricings Updated Successfully");
      } else {
        this.mposTerminal = false;
        alert("Terminal Price Created Successfully");
      }
      this.isLoading = false;
    },
    async linuxPrice() {
      this.isLoading = true;
      let payload = {
        TerminalType: "Linux",
        OutrightPurchase: this.linuxPayload.outrightPurchase,
        LeaseToOwn: this.linuxPayload.leaseToOwn,
        Subscription: this.linuxPayload.subscription,
        leaseToOwnDepositAmount: this.linuxPayload.leaseToOwnDepositAmount,
      };
      let res = await this.$store.dispatch("CREATE_TERMINAL_PRICING", payload);
      if (res.error === "LINUX terminal already exist") {
        let res = await this.$store.dispatch(
          "UPDATE_TERMINAL_PRICING",
          payload
        );
        this.linuxTerminal = false;
        alert("Terminal Pricings Updated Successfully");
      } else {
        this.linuxTerminal = false;
        alert("Terminal Price Created Successfully");
      }
      this.isLoading = false;
    },
    async androidPrice() {
      this.isLoading = true;
      let payload = {
        TerminalType: "Android",
        OutrightPurchase: this.androidPayload.outrightPurchase,
        LeaseToOwn: this.androidPayload.leaseToOwn,
        Subscription: this.androidPayload.subscription,
        leaseToOwnDepositAmount: this.androidPayload.leaseToOwnDepositAmount,
      };
      let res = await this.$store.dispatch("CREATE_TERMINAL_PRICING", payload);
      if (res.error === "ANDROID terminal already exist") {
        await this.$store.dispatch("UPDATE_TERMINAL_PRICING", payload);
        this.androidTerminal = false;
        alert("Terminal Pricings Updated Successfully");
      } else {
        this.androidTerminal = false;
        alert("Terminal Price Created Successfully");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.isLoading = true;
    await this.makeRequest();
    this.isLoading = false;
  },
  components: { Loading },
};
</script>

<style></style>
